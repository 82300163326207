<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
export default {
  name: "BoAboutYayasan",
  extends: Base,
  data() {
    return {
      Name: "BoAboutYayasan",
      data: [],
      row: {},
      input: {},
      about: {},
      ObjectName: "Slideshow",
      visiMisi: {},
      strukturOrganisasi: {},
      staff: [],
      heroImage: {},
      aboutYayasanHeroImage: "",
      showVisiMisi: "",
      lgmaxlength: 900
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData(() => {
      this.$set(
        this.$root.page,
        "ObjectName",
        this.replacePageTitle("Staf", "Yayasan Al Hidayah")
      );
      setTimeout(() => {
        this.$set(this.row, "type", "update");
      }, 500);
    });
    this.moduleRedirect();
  },
  computed: {
    aboutDesc: {
      get() {
        return this.about.desc;
      },
      set(newValue) {
        const dom = document.createElement('div');
        dom.innerHTML = newValue;
        const content = dom.textContent
                          .replaceAll('\n', '')
                          .replace(/\s{2,}/g, ' ')
                          .trim();
        $("[error='about_desc']").html(
          this.lgmaxlength - content.length < 0
          ? `<small class='error'>Input max ${this.lgmaxlength} characters</small>` 
          : `<small class='text-info'>${this.lgmaxlength - content.length} characters left</small>`
        );
        this.about.desc = newValue;
      }
    },
  },
  methods: {
    youtubeLink(link){
      let yt = link.split(/[/=]+/)
      let sub = yt[yt.length - 1]
      let newLink =  'https://www.youtube.com/embed/' + sub
      return newLink
    },
    submitHero() {
      BOGen.apirest(
        "/" + this.Name,
        this.row,
        (err, resp) => {
          if (resp.error)
            return Gen.info(resp.message, resp.style, 3000, ".info");
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".info");
            this.refreshData();
            setTimeout(() => {
              $("#collapseExample").hide();
            }, 3500);
          }
        },
        "POST"
      );
    },
    submitMisi() {
      this.visiMisi.type = "updateVisiMisi";
      
      if (this.visiMisi.visi.desc.length > 0) {
        $("[error='visi']").html("");
      }
      if (this.visiMisi.misi.desc.length > 0) {
        $("[error='misi']").html("");
      }
      if (this.visiMisi.tujuan.desc.length > 0) {
        $("[error='tujuan']").html("");
      }
      
      BOGen.apirest(
        "/" + this.Name,
        this.visiMisi,
        (err, resp) => {
          if (err) {   
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<span class='error'>" + textError + "</span>"
                );
              });
              $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $("#collapseVisiMisi").offset().top - 150,
                  },
                  500
                );
            }
          }
          if (resp.error)
            return Gen.info(resp.message, resp.style, 3000, ".info-misi");
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".info-misi");
            this.refreshData();
            setTimeout(() => {
              $("#collapseExample").hide();
            }, 3500);
          }
        },
        "POST"
      );
    },
    submitGoodness(e) {
      if (e && e.btnLoading()) return;
      BOGen.apirest(
        "/" + this.Name,
        this.input,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".col-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".col-info");
            this.refreshData();
            setTimeout(() => {
              $("#collapseNilai").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitAbout(e) {
      if (!this.moduleRole("Edit")) return;
      if (e && e.btnLoading()) return;
      this.about.type = "updateAbout";
      BOGen.apirest(
        "/" + this.Name,
        this.about,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
                $([document.documentElement, document.body]).animate(
                  {
                    scrollTop: $("#collapseAbout").offset().top - 150,
                  },
                  500
                );
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".about-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".about-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitStruktur(e) {
      if (e && e.btnLoading()) return;
      this.strukturOrganisasi.type = "updateStruktur";
      BOGen.apirest(
        "/" + this.Name,
        this.strukturOrganisasi,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".struktur-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".struktur-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitStaffGuru(e) {
      if (e && e.btnLoading()) return;

      if(this.row.ai_img){
        $("[error='ai_img']").html("");
      }

      this.row.type = this.$route.params.id == "add" ? "add" : "update";
      BOGen.apirest(
        "/" + this.Name,
        this.row,
        (err, resp) => {
          if (e) e.btnUnloading();

          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            return;
          }

          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".info-staff-guru");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".info-staff-guru");
            setTimeout(() => {
              this.redirectToList();
              this.row = {};
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitPrestasi(e) {
      if (e && e.btnLoading()) return;
      this.prestasi.type = "updatePrestasi";
      BOGen.apirest(
        "/" + this.Name,
        this.prestasi,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".prestasi-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".prestasi-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitImgPrestasi(e) {
      if (e && e.btnLoading()) return;
      BOGen.apirest(
        "/" + this.Name,
        this.aprImgInput,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".prestasi-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".prestasi-info");
            this.refreshData();
            setTimeout(() => {
              $("#collapseImage").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitFasilitas(e) {
      if (e && e.btnLoading()) return;
      BOGen.apirest(
        "/" + this.Name,
        this.fasilitasRaw,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".fasilitas-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".fasilitas-info");
            this.refreshData();
            setTimeout(() => {
              $("#collapsFasilitas").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitShowTestimoni(e) {
      if (e && e.btnLoading()) return;
      this.testi.type = "testi";
      BOGen.apirest(
        "/" + this.Name,
        this.testi,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".testi-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".testi-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitArticle(e) {
      if (e && e.btnLoading()) return;
      this.article.type = "article";
      BOGen.apirest(
        "/" + this.Name,
        this.article,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".article-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".article-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitHeroImage(e) {
      BOGen.apirest(
        "/" + this.Name,
        {
          type: "updateHero",
          heroImage: {
            as_val: {
              img: this.heroImage.as_val.img,
              img_mobile: this.heroImage.as_val.img_mobile,
              alt_img: this.heroImage.as_val.alt_img,
              alt_img_mobile: this.heroImage.as_val.alt_img_mobile,
            },
          },
        },
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".kr-img-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".kr-img-info");
            setTimeout(() => {
              $("#modalAboutYayasanImg").modal("hide");
            }, 500);
            this.refreshData();
          }
        },
        "POST"
      );
    },
    addImage() {
      $("#collapseImage").css("display", "flex");
      this.aprImgInput = {};
      this.aprImgInput = {
        ap_is_active: "Y",
      };
      this.aprImgInput.type = "addImagePrestasi";
    },
    editImage(v) {
      $("#collapseImage").css("display", "flex");
      this.aprImgInput = v;
      this.aprImgInput.type = "updateImagePrestasi";
    },
    addSlide() {
      $("#collapseExample").css("display", "flex");
      this.row = {};
      this.row = {
        as_is_active: "Y",
        as_link_target: "_self",
        as_title: "",
        as_link: "",
      };
      this.row.type = "add";
    },
    editSlide(v) {
      $("#collapseExample").css("display", "flex");
      this.row = v;
      this.row.type = "update";
      this.row.as_link_target =
        this.row.as_link_target == "" ? "_self" : this.row.as_link_target;
    },

    editStatistik(v) {
      $("#collapseNilai").css("display", "flex");
      this.input = v;
      this.input.type = "updateStatistik";
    },
    addFasilitas() {
      $("#collapsFasilitas").css("display", "flex");
      this.fasilitasRaw = {};
      this.fasilitasRaw = {
        ap_is_active: "Y",
      };
      this.fasilitasRaw.type = "addFasilitas";
    },
    editFasilitas(v) {
      $("#collapsFasilitas").css("display", "flex");
      this.fasilitasRaw = v;
      this.fasilitasRaw.type = "updateFasilitas";
    },
    endDrag() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.data,
          type: "sort",
        },
        (err, resp) => {
          
        },
        "POST"
      );
    },
    editHeroImage(v) {
      $("#modalAboutYayasanImg").modal();
      this.aboutYayasanHeroImage = v;
    },
    toggleShow(as_id, e) {
      const show = e.target.checked ? "Y" : "N";
      swal({
        title: `Apakah anda yakin untuk ${ e.target.checked ? 'menampilkan' : 'menyembunyikan' } section ini?`,
        text: "",
        icon: "warning",
        buttons: ["Tidak, Kembali!", `Ya, ${e.target.checked ? 'Tampilkan' : 'Sembunyikan'}!`],
        dangerMode: false,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            {
              type: "toggleShow",
              as_id,
              show,
            },
            (err, resp) => {
              if (resp.success) {
                swal(resp.message, "", "success");
                this.refreshData();
              }
            },
            "POST"
          );
        }
      });
    },
  },
  watch: {
    "$route.query"() {
      this.refreshData();
    },
    "row.as_title"(v) {
      this.slTitleLength = v.length;
    },
    "$route.params"() {
      this.$set(
        this.$root.page,
        "ObjectName",
        this.replacePageTitle("Staff", "Yayasan Al Hidayah")
      );
      this.row = {};
      
      if(this.$route.params.id){
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: 0,
          },
          0
        );
      }
    },
    "row.ai_position"(v) {
      if (v.length > 0) {
        $("[error='ai_position']").html("");
      }
    },
    "row.ai_name"(v) {
      this.row.ai_alt_img = v;
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='ai_name'] label").text("Input min 3 characters");
        } else {
          $("[error='ai_name']").html("");
        }
        $("[error='ai_alt_img']").html($("[error='ai_name']").html());
      }
    },
    "row.ai_description"(v) {
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='ai_description'] label").text("Input min 3 characters");
        } else {
          $("[error='ai_description']").html("");
        }
      }
    },
    "row.ai_img"(v) {
      if (v.length > 0) {
        $("[error='ai_img']").html("");
      }
    },
    "about.desc"() {
      let plain = $("<div/>").html(CKEDITOR.instances.desc.getSnapshot()).text();
      if (plain.length > 0) {
        if (plain.length < 10) {
          $("[error='desc'] label").text("Input min 10 characters");
        } else {
          $("[error='desc']").html("");
        }
      }
    },
    "about.title"(v) {
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='title'] label").text("Input min 3 characters");
        } else {
          $("[error='title']").html("");
        }
      }
    },
    "about.youtube"(v) {
      if (v.length > 0) {
        $("[error='youtube']").html("");
      }
    },
    "visiMisi.visi.desc"() {
      let plain = $("<div/>").html(CKEDITOR.instances.visi.getSnapshot()).text();
      if (plain.length > 0) {
        if (plain.length < 10) {
          $("[error='visi'] span").text("Input min 10 characters");
        } else {
          $("[error='visi']").html("");
        }
      }
    },
    "visiMisi.misi.desc"() {
      let plain = $("<div/>").html(CKEDITOR.instances.misi.getSnapshot()).text();
      if (plain.length > 0) {
        if (plain.length < 10) {
          $("[error='misi'] span").text("Input min 10 characters");
        } else {
          $("[error='misi']").html("");
        }
      }
    },
    "visiMisi.tujuan.desc"() {
      let plain = $("<div/>").html(CKEDITOR.instances.tujuan.getSnapshot()).text();
      if (plain.length > 0) {
        if (plain.length < 10) {
          $("[error='tujuan'] span").text("Input min 10 characters");
        } else {
          $("[error='tujuan']").html("");
        }
      }
    },
  },
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle :no-add-item="true"></PageTitle>
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              Hero Image Yayasan Al-Hidayah
            </h5>
          </div>
          <div class="card-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <div class="row">
                <div v-if="moduleRole('Edit') && !$route.params.id" class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="hero"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image Mobile'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="heromobile"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img_mobile"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img"
                        v-model="heroImage.as_val.alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image SDI Al-Hidayah'
                        }"
                        :label="'Alt Image'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img_mobile"
                        v-model="heroImage.as_val.alt_img_mobile"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image SDI Al-Hidayah'
                        }"
                        :label="'Alt Image Mobile'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                  </div>
                </div>
                <div v-else class="col-md-8">
                  <img
                    :src="uploader(heroImage.as_val.img)"
                    :alt="strukturOrganisasi.as_val.alt_img"
                  />
                </div>
                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!$route.params.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Tentang Yayasan Al Hidayah</h5>
            </div>
          </div>
          <div class="card-body ">
            <VForm @resp="submitAbout" id="collapseAbout" method="post">
              <div class="row">
                <div class="about-info col-12"></div>
                <div class="col-md-6">
                  <BoField
                    name="title"
                    :label="'Judul'"
                    v-model="about.title"
                    :attr="{
                      placeholder: 'e.g. Yayasan Al Hidayah'
                    }"
                    mandatory
                  ></BoField>
                  <BoField
                    name="postTitle"
                    :label="'Sub Judul'"
                    v-model="about.postTitle"
                    :attr="{
                      placeholder: 'e.g. Yayasan Al Hidayah'
                    }"
                  ></BoField>
                  <BoField name="desc" mandatory :label="'Deskripsi'">
                    <CKEditor
                      class="form-control"
                      minlength="10"
                      name="desc"
                      v-model="aboutDesc"
                      v-bind="validation('desc')"
                      required=""
                    ></CKEditor>
                    <div error="about_desc"></div>
                  </BoField>
                </div>
                <div class="col-md-6">
                  <BoField
                    name="youtube"
                    :label="'Link Youtube'"
                    v-model="about.youtube"
                    :attr="{
                      placeholder: 'e.g. https://www.youtube.com/watch?v=77ZqZOc5XnU'
                    }"
                    mandatory
                  >
                  </BoField>
                  <div class="about-video">
                    <iframe
                      style="overflow:hidden;width:100%"
                      width="100%"
                      height="315px"
                      :src="youtubeLink(about.youtube)"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!$route.params.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Visi Misi &amp; Tujuan</h5>
            </div>
          </div>
          <div class="card-body ">
            <div v-if="moduleRole('Edit')">
              <div class="custom-control custom-checkbox">
                <input
                  class="custom-control-input"
                  :checked="showVisiMisi.as_val.show === 'Y'"
                  @click.prevent="toggleShow(1, $event)"
                  id="sekilasCheck"
                  type="checkbox"
                />
                <label class="custom-control-label" for="sekilasCheck"
                  >Tampilkan section?</label
                >
              </div>
              <hr />
            </div>
            <VForm @resp="submitMisi" method="post">
              <div class="row">
                <div class="info-misi col-12"></div>
                <div class="col-md-6">
                  <BoField name="visi" :label="'Visi'">
                    <CKEditor
                      class="form-control"
                      minlength="10"
                      name="visi"
                      v-model="visiMisi.visi.desc"
                      v-bind="validation('desc')"
                      required=""
                    ></CKEditor>
                  </BoField>
                  <BoField name="tujuan" :label="'Tujuan'">
                    <CKEditor
                      class="form-control"
                      minlength="10"
                      name="tujuan"
                      v-model="visiMisi.tujuan.desc"
                      v-bind="validation('desc')"
                      autogrowconfig="off"
                      required=""
                    ></CKEditor>
                  </BoField>
                </div>
                <div class="col-md-6">
                  <BoField name="misi" :label="'Misi'">
                    <CKEditor
                      class="form-control"
                      minlength="10"
                      name="misi"
                      v-model="visiMisi.misi.desc"
                      v-bind="validation('desc')"
                      autogrowconfig="off"
                      required=""
                    ></CKEditor>
                  </BoField>
                </div>

                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!$route.params.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">
                Struktur Organisasi
              </h5>
            </div>
          </div>
          <div class="card-body ">
            <VForm @resp="submitStruktur" method="post">
              <div class="row">
                <div class="struktur-info col-12"></div>
                <div v-if="moduleRole('Edit')" class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Gambar Struktur Organisasi'">
                        <Uploader
                          name="image"
                          :param="{ thumbnail: true }"
                          type="struktur"
                          uploadType="cropping"
                          v-model="strukturOrganisasi.as_val.img"
                          :deleted="false"
                        ></Uploader>
                      </BoField>
                    </div>
                    <div class="col-md-8">
                    <BoField
                        name="so_organisasi"
                        v-model="visiMisi.visi.active"
                        :label="'Tampilkan Section?'"
                      >
                        <radio v-model="strukturOrganisasi.as_val.active" option="Y"
                          >Ya</radio
                        >
                        <radio v-model="strukturOrganisasi.as_val.active" option="N"
                          >Tidak</radio
                        >
                      </BoField>
                      <BoField
                        mandatory
                        name="alt_img"
                        v-model="strukturOrganisasi.as_val.alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Struktur Organisasi SDI Al Hidayah'
                        }"
                        :label="'Alt Image'"
                        required=""
                      ></BoField>
                    </div>
                  </div>
                </div>
                <div v-else class="col-md-8">
                  <img
                    :src="uploader(strukturOrganisasi.as_val.img)"
                    :alt="heroImage.as_val.alt_img"
                  />
                </div>
                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="card-title mb-0">Staf</h5>
              <router-link
                :to="{ name: Name, params: { id: 'add' } }"
                class="btn btn-info btn-rounded"
                v-if="moduleRole('Add')"
                ><i class="fa fa-plus-circle m-r-10"></i>Tambah Staf</router-link
              >
            </div>
          </div>
          <div class="card-body">
            <div v-if="staff.length === 0" class="row">
              <div class="text-center col-md-12">
                <h5 class="tc">Data belum tersedia</h5>
              </div>
            </div>
            <div v-else class="row gutter-20">
              <div class="col-md-3" v-for="(v, k) in staff" :key="k">
                <div class="item-list collection">
                  <div class="row text-center">
                    <div class="col-md-12">
                      <div class="product-img recipe-cat mb-2">
                        <img :src="uploader(v.ai_img, '250')" alt="prod" />
                        <div class="bullet-cta" style="cursor:default;">
                          <router-link
                            class="bullet_edit"
                            v-if="moduleRole('Edit')"
                            :to="{
                              name: Name,
                              params: { id: v.ai_id },
                              query: $route.query,
                            }"
                            v-tooltip="'Ubah'"
                            ><i class="ti-marker-alt"></i
                          ></router-link>
                          <a
                            href="javascript:;"
                            class="bullet_change_status bg-warning"
                            v-if="moduleRole('Edit')"
                            @click="
                              changeStatusDynamic(
                                v,
                                'ai_is_active',
                                'ai_id',
                                'AppInstructor'
                              )
                            "
                            v-tooltip="'Ubah Status'"
                            ><i class="ti-settings"></i
                          ></a>
                          <a
                            href="javascript:;"
                            class="bullet_delete"
                            v-if="moduleRole('Delete')"
                            @click="
                              deleteItemId(v.ai_id, 'dat' + v.ai_id, 'Staff')
                            "
                            v-tooltip="'Hapus'"
                            ><i class="ti-trash"></i
                          ></a>
                        </div>
                         <label style="box-sizing: border-box" class=" mt-2 text-white py-1 rounded px-3 justify-content-center label-success"
                                  v-if="v.ai_is_active == 'Y'"
                                  >Active</label>
                                <label style="box-sizing: border-box" class=" mt-2 text-white py-1 rounded px-3 justify-content-center label-danger" v-else
                                  >Inactive</label>
                        <h5
                          class="card-title mt-1"
                          v-tooltip="v.ai_name.limitChar(40).endsWith('...') ? v.ai_name : ''"
                        >
                          {{
                            v.ai_name.limitChar(40)
                          }}
                        </h5>
                        <small>{{ v.ai_description }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="$route.params.id">
        <div class="card">
          <VForm @resp="submitStaffGuru" method="post">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">
                    {{ row.ai_id ? "Ubah" : "Tambah" }} {{ ObjectName }}
                  </h5>
                </div>
              </div>
              <div class="info-staff-guru"></div>
              <div class="row">
                <div class="col-md-8">
                  <div class="col-md-12 mb-3">
                    <BoField
                      class="mb-0"
                      name="an_title"
                      v-model="row.ai_name"
                      :label="'Nama'"
                      :attr="{
                        maxlength: '100',
                        minlength: '3',
                        placeholder: 'e.g. Galih Kusuma'
                      }"
                      mandatory
                    ></BoField>
                    <div error="ai_name"></div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <BoField
                      name="an_slug"
                      class="mb-0"
                      :label="'Keterangan'"
                      v-model="row.ai_description"
                      :attr="{ placeholder: 'e.g. Bendahara Yayasan' }"
                      mandatory
                    ></BoField>
                    <div error="ai_description"></div>
                  </div>
                  <div class="col-md-12">
                    <BoField name="ai_is_active" :label="'Status'" mandatory>
                      <radio
                        v-model="row.ai_is_active"
                        option="Y"
                        :attr="validation('an_is_active')"
                        >Active</radio
                      >
                      <radio v-model="row.ai_is_active" option="N"
                        >Inactive</radio
                      >
                    </BoField>
                  </div>
                </div>
                <div class="col-md-4">
                  <BoField name="an_img" class="mb-0" mandatory>
                    <Uploader
                      name="an_img"
                      type="guru_staff"
                      uploadType="cropping"
                      :deleted="false"
                      :param="{ thumbnail: true }"
                      v-model="row.ai_img"
                    ></Uploader>
                    <div error="ai_img"></div>
                  </BoField>

                  <BoField
                    mandatory
                    name="ai_alt_img"
                    v-model="row.ai_alt_img"
                    :attr="{
                      type: 'text',
                      placeholder: 'e.g. Galih Kusuma'
                    }"
                    :label="'Alt Image'"
                  ></BoField>

                </div>
                <div class="col-sm-12 justify-content-end">
                  <div class="text-right">
                    <router-link
                      :to="{ name: $route.name }"
                      class="fcbtn btn btn-light btn-outline btn-1e btn-loading btn-rounded mr-2"
                    >
                      Kembali
                    </router-link>
                    <button
                      type="submit"
                      class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded"
                    >
                      {{ this.$route.params.id == "add" ? "Tambah" : "Ubah" }}
                      <i class="icon-arrow-right14 position-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>
